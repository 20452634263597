import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  static values = { index: Number }

  connect() {
    this.showTab()
  }

  indexValueChanged() {
    this.showTab()
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index]
      const selected = index === this.indexValue
      tab.setAttribute("aria-selected", selected)
      tab.classList.toggle("active", selected)
      panel.hidden = !selected
    })
  }

  change(event) {
    this.indexValue = this.tabTargets.indexOf(event.currentTarget)
  }
}