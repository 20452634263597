import NestedForm from "@stimulus-components/rails-nested-form"

export default class extends NestedForm {
  connect() {
    super.connect()
    this.updateIndices()
  }

  add(event) {
    super.add(event)
    requestAnimationFrame(() => {
      this.updateIndices()
    })
  }

  remove(event) {
    super.remove(event)
    this.updateIndices()
  }

  updateIndices() {
    const firstLegend = this.element.querySelector('legend')
    if (!firstLegend) return
    
    // Special handling for "Involuntary Lien"
    const legendText = firstLegend.textContent.trim()
    let type
    if (legendText.startsWith('Involuntary Lien')) {
      type = 'involuntary-lien'
    } else {
      type = legendText.split(' ')[0].toLowerCase()
    }
    
    const wrappers = this.element.querySelectorAll('[data-rails-nested-form-wrapper]')
    let count = 1

    wrappers.forEach(wrapper => {
      if (wrapper.style.display !== 'none') {
        const indexSpan = wrapper.querySelector(`[data-${type}-index]`)
        if (indexSpan) {
          indexSpan.textContent = count++
        }
      }
    })
  }
}